import {fileUpload} from '@/api/apis'
import config from "@/utils/config";
import {message} from "ant-design-vue";
// 工具栏
export const toolbarConfig = {
    /* 工具栏配置 */
    toolbarKeys: [
        'bold',
        'clearStyle',
        'color',
        'bgColor',
        'fontSize',
        '|',
        // 菜单组，包含多个菜单
        {
            key: 'group-image', // 必填，要以 group 开头
            title: '图片', // 必填
            iconSvg: '<svg></svg>',
            menuKeys: ['uploadImage',
                'insertImage',
                'deleteImage',
                'editImage',
                'viewImageLink']
        },
        'divider',
        'emotion',
        'blockquote',
        'headerSelect',
        'redo',
        'undo',
        'fullScreen'
     ]
}
export const MENU_CONF= {
    uploadImage: {
      async customUpload (file, insertFn) {
        console.log(file)
        // // 上传图片
        const fd = new FormData()
        fd.append('files', file)
        try {
          const res = await fileUpload(fd)
          //console.log(res)
          // 如果上传成功
          if (res) {
              insertFn(config.imgBaseURL()+res.data.fileName)
          }
        } catch (err) {
          message.error('上传图片错误')
        //console.log(err)
        }
      },
    },
  }
